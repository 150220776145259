import React from "react";
import theme from "../../../../util/theme";
import { FlexDiv } from "../../../ui/FlexContainer";
import { AppLink } from "../../../ui/AppLink";
import { Awards } from "../../index/Awards";
import styled from "styled-components";
import { Wrapper } from "../../../ui";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import { OfferSegmentI } from "../../../../hooks/useOffer";
import { calculateDiscountedPrice } from "../../../../util/numbers";
import { offer_landing_page_click } from "../../../../services/mixpanel/discount-flow-events";
import { mobileSize } from "../../../../util/variables";

const Offer: React.FC<OfferSegmentI> = ({ priceData }) => {
  const { trial, percentOff, annual_price, currency_symbol, price_id, coupon_id } = priceData;
  const discountedPrice = calculateDiscountedPrice(Number(annual_price), percentOff ?? 0);
  return (
    <OfferContainer>
      <OfferBadge>Limited Time Offer</OfferBadge>
      <FlexDiv direction="column" align="center">
        <h2>Imprint Subscription</h2>
        <Pricing>
          <p>
            <FullPrice>{`${currency_symbol}${annual_price}`}</FullPrice>
            {" "}
            <DiscountPrice>{`${currency_symbol}${discountedPrice}/year`}</DiscountPrice>
          </p>
          <p>Cancel anytime</p>
        </Pricing>
        <AppLink
          to="/subscription/register?from=email"
          state={{
            couponId: coupon_id,
            priceId: price_id,
            trial,
          }}
          onClick={() => offer_landing_page_click("hero")}
        >
          Claim Offer
        </AppLink>
        <FeatureList>
          <li>Build a learning habit</li>
          <li>Master complex topics with insights from the world&apos;s greatest thinkers</li>
          <li>Replace doomscrolling with bite-sized learning</li>
        </FeatureList>
      </FlexDiv>
    </OfferContainer>
  );
};

export const HeroSection: React.FC<OfferSegmentI> = ({ priceData }) => {
  const { percentOff } = priceData;
  const dataHero = useStaticQuery(graphql`
    query OfferHeroImageQuery2 {
      coverImage: file(relativePath: { eq: "offer/coverImages.webp" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `);

  return (
    <Section>
      <Wrapper>
        <FlexDiv direction="column" align="center">
          <HeroTitle>
            {`Kickstart your growth with ${percentOff}% off`}
          </HeroTitle>
          <HeroParagraph>
            See the world in a whole new way with unlimited access to
            Imprint&apos;s complete library of visual learning.
          </HeroParagraph>
          <ImageContainer>
            <Img
              fluid={dataHero.coverImage.childImageSharp.fluid}
              alt="Hero Image"
              loading="eager"
            />
          </ImageContainer>
          <Offer priceData={priceData} />
          <OfferTerms>
            Discount on in-app price. Recurring annual billing.
          </OfferTerms>
          <Awards dark darkLaurel />
        </FlexDiv>
      </Wrapper>
    </Section>
  );
};

const ImageContainer = styled.div`
  width: 100%;
  max-width: 512px;
  padding-bottom: 34px;
`;

const Pricing = styled.div`
  font-size: 18px;
  padding: 8px 0 16px 0;
`;

const Section = styled.section`
  background-color: ${theme.MINT};
  text-align: center;
  color: ${theme.BLACK_COLOR};
  padding: 50px 0;

  p {
    font-family: ${theme.SECONDARY_FONT};
  }

  h1 {
    padding: 0 8px;
  }
`;

const HeroTitle = styled.h1`
  max-width: 642px;
  @media ${mobileSize} {
    max-width: 350px;
  }
`;

const HeroParagraph = styled.p`
  line-height: 22px;
  padding: 0 16px;
  max-width: 54ch;
  font-size: 18px;
`;

const OfferBadge = styled.span`
  background-color: #FDF48B;
  font-size: 14px;
  font-weight: 700;
  line-height: 28px;
  padding: 6px 16px;
  border-radius: 8px;
  text-transform: uppercase;
`;

const FullPrice = styled.span`
  text-decoration: line-through;
  color: ${theme.RED};
  font-weight: 300;
  font-size: 24px;
`;

const DiscountPrice = styled.span`
  font-weight: 500;
  font-size: 24px;
`;

const OfferContainer = styled.div`
  background-color: ${theme.WHITE_COLOR};
  border-radius: 16px;
  box-shadow: 0px 1px 7px 0px #2C273814;
  padding: 16px 32px;
  max-width: 512px;

  h2 {
    font-weight: 600;
    font-size: 36px;
    margin: 16px 0 0 0;
  }

  p {
    margin: 0;
  }

  @media ${mobileSize} {
    p {
      font-size: 20px;
    } 
    h2 {
      font-size: 24px;
    }
  }
`;

const FeatureList = styled.ul`
  text-align: left;
  font-size: 20px;
  margin: 0;
  padding: 20px 0 0 0;
  li {
    position: relative;
    padding-left: 36px;
    list-style-type: none;

    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 24px;
      height: 24px;
      background-image: url('/svg/checkMark.svg');
      background-size: contain;
      background-repeat: no-repeat;
    }
  }

  @media ${mobileSize} {
    font-size: 18px;
  }
`;

const OfferTerms = styled.p`
  font-size: 16px;
  font-style: italic;
  line-height: 20px;
  padding: 16px 10px;
  color: ${theme.BLACK_COLOR};
`;
